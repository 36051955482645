@import "~@flaticon/flaticon-uicons/css/all/all";
@import url(//db.onlinewebfonts.com/c/643121b5d2cc9943eff9b59054cc29ba?family=DINNextRoundedLTW01-Light);
body {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden; 
 }
body p { text-align: justify;}
.navbar-light {
    border: none;
    margin-bottom: 0;
    padding: 0px 0px;
}
.navbar-expand-lg{    border: none;background-color: rgb(0 0 0 / 50%);    z-index: 2;

}
.topBar .navbar { border: none;}
.topBar .navbar a { color: #c0c4c7;     padding: 5px 10px; font-size: 13px;
    font-weight: 400;
}
.navbar-brand .img-fluid {
    max-width:34%;
    height: auto;
}
.bannerText  { display: flex; align-items:  flex-start;}
.bannerText h2 { color: #fff; font-size:70px;   font-weight: 
    700; text-transform: uppercase;}
    .BannerDiv { min-height:100vh; background-size: cover; background-position: center; background-repeat: no-repeat; display: flex; align-items: center}

.navbar-light .col-lg-6 {
        padding: 0px 15px;

}
.advantageSection { background-color: #f1f1f1;}
.advantageDiv { background-color: #fff; border-radius: 10px; box-shadow: 2px 4px 12px rgb(0 0 0/8%); padding: 20px; margin-bottom: 20px;     min-height: 200px;
}
.advantageDiv h4 { text-transform: uppercase; font-weight:600; font-size: 19px;}
.advantageDiv p {   font-size: 16px;}
button.btn.btn-default, button.btn.btn-default:focus  {
    background-color: #fff;
    color: #00386b;
    border-radius: 30px;
    padding: 10px;
    border-color: #00386b;
    transition: .5s;
    font-weight: 600;
}

  button.btn.btn-default:hover {
    background-color:#00386b ; border-color:#00386b ; color: #fff;  border-radius: 30px; padding: 10px; -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;}
    button.btn-success , button.btn.btn-success:focus { background-color:#00386b ; border-color:#00386b ; color: #fff;  border-radius: 30px; padding: 10px; -webkit-transition: .5s;
        -o-transition: .5s;
        transition: .5s;     font-weight: 600;
    }
   
 button.btn-success:hover{   background-color: #fff;
    color: #00386b; border-radius: 30px; padding: 10px; border-color: #00386b; -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;}

   
.navbar .col-lg-6 a , .navbar .col-lg-3 a{
    line-height: 50px;
}
a, a:hover {
    color: #00386b;
    text-decoration: none;-webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.topBar {
    background-color:#00386b;
    color: #fff;
    padding: 0px 0px;
    text-align: center;
}
.topBar a {
     color: #fff;
     text-align: center;
}
.topBar a img { margin-right: 5px;}
.topBar .col-lg-9 li {   position: relative;
    padding: 8px 0px;     margin-right: 30px;
}
.topBar .col-lg-3 li{   line-height: 39px;}
.topBar .col-lg-3 li i{  padding: 8px 0px; margin-right:10px; color: #ffcb15;}
.topBar .col-lg-9 li::after { position: absolute; content: ""; height: 100%; width: 1px; background-color: #3a556a;right: -15px;top: 0; }
.topBar .col-lg-9 li:last-child::after{ display: none;}
 
a.nav-link {
    font-weight: 600;
    color: #008ae7;
}
.cardBox2 {
    width: 100%;
    height:100%;
    position: relative;
    display: inline-block;
    margin: auto;
 }
.img-top1 { margin: auto;    display: block;
}
.navbar img { max-width: 70%;}
.cardBox2 .img-top {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    z-index: 99;    margin: auto;

}
.cardBox2:hover .img-top {
    display: inline;
}
.process-items .item:hover .img-top {
    display: inline;
}
 .navbar-nav span {
    font-size: 13px;
    font-weight: 600;
    color: #213ca8;
}
.navbar-nav a {
    display: block;
    color: #00386b;
    font-weight: 600;
    padding: 20px 4px;
    position: relative;
    font-size: 16px;  margin-right: 0px; 
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
}
.navbar-nav a:hover{ color: #ffcb15!important; -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;transition: all 0.35s ease-in-out;}
.cardSection { background-color: #f1f1f1;}
.companyProgress img {    height: 60px;
    margin-bottom: 20px;}
    .yellow {color: #ffcb15!important;}
    .companyProgress p { text-transform: capitalize;}

    .connectSection { background-color: #f2f2f2;}
    .connectSection .col-lg-6 { min-height: 600px;
        background-repeat: no-repeat;
        background-position: center top;
       
        background-size: cover;}
        .AboutBg{ background-repeat: no-repeat;
            background-position: bottom;
            background-size: contain;}
        .connectSection .conncetDiv { padding: 85px 50px;  margin-top:10%;}
        .connectSection .container h5 { color: #003075!important; }
        button.btn.btn-primary  {
            background-color: transparent;
            border-radius: 0px;
            color: #00386b;
            border: 2px solid #00386b;
            padding: 9px;-webkit-transition: all 0.35s ease-in-out;
            -moz-transition: all 0.35s ease-in-out;transition: all 0.35s ease-in-out;
        }
        button.btn.btn-primary:hover { background-color: #00386b;
            border-radius: 0px;
            color: #f1f1f1;
            border: 2px solid #00386b;
            padding: 9px;-webkit-transition: all 0.35s ease-in-out;
            -moz-transition: all 0.35s ease-in-out;transition: all 0.35s ease-in-out;}
.serviceSec {background-color: #f1f1f1; }
.serviceDiv {background-color: #fff; border-radius: 30px;  min-height: 510px; margin-bottom: 30px;}
.container h1 { color: #00386b;/* text-align: center; */  font-weight: 700; font-size: 45px;
    margin-bottom: 0px;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#00aeef,#2b3990);
    background: linear-gradient(#00aeef,#2b3990);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;}
.container h5 {color: #ffcb15;/* text-align: center; */  font-weight: 400;  font-size: 19px;    margin-bottom: 0px; text-transform: uppercase; }
.AboutSec p {
    font-size: 18px;
    margin-bottom: 25px;
    line-height: 35px;
    font-weight: 500;
}
.footerSection { background-color: #00386b;}
.footerSection h5 {
    color: #ffcb15;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 15px;
}
#background-video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    left: 0;
    right: 0;
    top: 0px;
    bottom: 0;
     z-index: -1;
     margin-top: -101px;
}
.Cardtitle { text-transform: uppercase; color: #003075;   text-align: center;}
.form-label {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}
.footerSection img { max-height: 100px;}
.container p a {
    color:  #00386b !important;
    font-weight: 600;
     padding: 3px;
}
.grayBg { background-color:#f4f5f9 ;}
.grayBg .table  { background-color:#851b1b ;}
.chartDiv .green { color: #00a64a;}
.chartDiv h4 { position: relative; margin-bottom: 30px; }
.chartDiv h4:after { position: absolute; content: ""; background-color: #00a64a; width: 60%; height: 5px; left: 0; bottom: -15px; }
.bookingform { background-color: #f1f1f1; padding: 0px 15px;   border-radius:10px;}
.AboutBg { position: relative ;     min-height: 801px;
}
.AboutBg h1{ position: relative ; color: #00386b;}
.AboutBg p { font-size: 16px; color: #5d5e5f; margin-bottom: 41px;}
.HomeForm h4 {
    font-size: 22px;
    text-transform: uppercase;
    color: #00386b;
    font-weight: 500;
    margin: 15px AUTO;
}
.HomeForm .form-control {
    height: 60px;
    border-radius: 0px;
    width: 100%;
    background-color: #f2f2f2;
    border-color: #00386b;color: #cacbcd;
} 
.HomeForm::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cacbcd;
    opacity: 1; /* Firefox */
  }
  
  .HomeForm:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #cacbcd;
  }
  
  .HomeForm::-ms-input-placeholder { /* Microsoft Edge */
    color: #cacbcd;
  }
  .owl-prev img {
    height: 35px;
}
.owl-next img {
    height: 39px;
}
.HomeForm button.btn.btn-warning  {
    background-color: #ffcb15;
    width: 100%;
    height: 60px;
    border-radius: 0px;
    text-align: center;
    color: #00386b;
    border: 2px solid #ffcb15;
    padding: 9px;-webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;transition: all 0.35s ease-in-out;
}
.HomeForm button.btn.btn-warning:hover { 
    background-color: #00386b;
    border-radius: 0px;
    color: #f1f1f1;
    border: 2px solid #00386b;
    padding: 9px;-webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;transition: all 0.35s ease-in-out;}
    
    .AboutBg .owl-item .item {
    padding: 15px;
    border: 1px solid #f1f1f1;
     background-color: #fff;
    box-shadow: 3px 5px 15px -9px rgba(0,0,0,0.75);}
    .AboutSec .col-lg-6 img { display: block; max-width: 80%; margin: auto;}
.owl-stage {
    padding-bottom: 26px;
}
.HomeForm .form-control:focus{box-shadow: none;}
.aboutSection p { color: #fff;font-size: 17px;
    line-height: 28px; }
/* .AboutBg:before { position: absolute ; content:""; left: 0; right: 0; width: 100%; height: 100%;background-color: rgb(0 0 0 / 70%);  } */
.footerSection .container p a {
    color:  #ffffff !important;
   font-size: 15px;
    font-weight: 400;
}
 .footerSection .container p , .footerSection li a , .footerSection li a.active{ color: #ffffff !important; font-size: 14px;
    font-weight: 400;} 
    .footerSection .col-lg-2  li a{color: #ffcb15!important;}
    .footerSection .fa { margin-right: 5px;}
body p {
    font-size: 14px;           line-height: 27px; 


}
.para {display: table; margin-left: 20px;     width: 90%;
}
.addressDiv p{ margin-bottom: 20px; display:-webkit-box;     text-align: left;
}
a:hover , a{
    text-decoration-line: none;
}
.HowCamline li { 
    font-size: 17px;
    line-height: 30px;
    background-color: #00386b;
    color: #fff;
    margin-bottom: 10px;
    padding: 2px 9px;
}
.companyProgress {   padding: 100px 0px;background-repeat: no-repeat;
    background-size: cover;     background-attachment: fixed;
}
.companyProgress h1 { font-size: 50px; font-weight:500; color: #FFF!important; background:#ffcb15;-webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;  text-align: center;}
.companyProgress p { font-size:18px; font-weight: 500;  color: #FFF;     text-align: center;
}
.companyProgress .col-lg-3 { margin-bottom: 
    20px;}
 .imageF2 {margin-right: 10px;}
 .addressDiv p a { margin-right: 20px;}
.testimonials , .process-items{ background-color: #f1f1f1;}
.testimonials h2 { font-size: 38px;
    font-weight: 700;
    color: #000;
    margin-bottom: 0px;
    text-transform: capitalize;     line-height: 45px;
}
.testimonials h5 { font-weight: 600; color: #000; }
 .testimonials p {  color: #000; text-align: center;   }
.reasonsSec .col-lg-12 p{ font-size: 15px;
    text-align: left; color: #939393;}
.reasonsSec img {float: left;    height:100px;width:100px;}
.reasonsSec .text {float: left;
    margin-left: 20px;
    width: 72%;
    margin-top: 10px; color:#c0c4c7;}
    .reasonsSec .text h4 { font-weight: 600;
        color: #000;
        font-size: 20px;
        margin: 10px 0px 5px;}
        .testiText { position: relative;
            line-height: 28px;
            padding: 5px 20px 5px 0px;
            margin: 20px 0px;       color:#939393;}
        .testiText:after{position: absolute; content: ""; background-image: url('/public/assets/images/petrol/Comma.png'); left: 0;
            background-size: contain;
            height: 80px;
            width: 80px;
            top: -30px;
            background-position: left;
            background-repeat: no-repeat; }
.testimonials h4 {  color:#00386b; text-transform: uppercase; font-weight: 700;     letter-spacing: 1px;
}
.testimonials p {  color:#00386b; margin-top: 5px; text-align: left;  }
.clarity { background-color: #f1f1f1;}
.clarity h5 { font-weight: 600;  }
.clarity p { font-weight: 500; color: #000; text-align: center; margin-top: 10px;  font-size: 14px;}
.whyChoose { background-color:#00386b ;}
.whyChoose h1 , .whyChoose p{ color: #fff;}
 .testimonials .col-lg-6{ position: relative;}
 .clarity img { width: 80px; height: 80px; margin: auto;  }
 .clients img{ max-height: 80px; margin: auto;  }
.testimonials .col-lg-6:first-child:after{ position: absolute; content: ""; width: 1px; height: 100%; background-color: #adadad; right: 0; top: 0;}
.components img { max-width: 80%;}
.components p { text-align: center; margin-top: 8px; font-weight: 600;}

/*  */
.process-items .item {
    -o-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
    box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
    padding: 20px;
    border-top: 2px solid #00386b;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background: #ffffff;
    min-height: 275px;
}
 .process-items .item:hover::after {
    top: 0;
}
 .process-items .item::after {
    position: absolute;
    left: 0;
    top: -100%;
    content: "";
    height: 100%;
    width: 100%;
    background: #00386b;
    z-index: -1;
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
}


 .process-items .item i {
    display:block;
    font-size: 50px;
    color: #00386b;
    margin-bottom: 20px;
    text-align: center;
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
}
 .process-items .item h4,  .process-items .item i   {
    color: #00386b; font-weight: 600;text-align: center;
}
.process-items .item p { color: #666666 ; height: 65px;}
 .process-items .item:hover h4,  .process-items .item:hover i , .process-items .item:hover p{
    color: #ffffff;
}
/*  */
.contactUS .fa {
    color: #00386b;
    font-size: 22px;
    margin-right: 7px;
    line-height: 38px;
}
h3.py-3, h4.pt-3 {
    color:  #00386b;
}
thead {
    background-color: #1f41af;
    color: #fff;
}
.faPara {
    display: table;
    margin-left: 10px;
    margin-top: 3px;     font-weight: 600;

}
 
tbody {
    background-color: #fbfbfb;
}
.blackBg {    background-color: #f1f1f1 !important; color: #000;
}
.BabjiproductDiv {
    border: 2px solid #607ea8;
    position: relative;
    margin-bottom: 60px;
    min-height: 200px;  -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.downloadSection{ display: flex ;align-items: center;}
.downloadSection a { color: #fff; text-transform: capitalize;    margin: auto;
    ;}
.BabjiproductDiv p { text-align: center;  position: absolute;
    right:0px;
    left: 0px; bottom: 0; color: #151716;font-size: 17px; -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;}
.BabjiproductDiv img {
    position: absolute;
    top: -60px;
     left: 0px;
    right: 0px;
    margin: auto;
}
.shipSection { background-color: #f4f5f9;}
.productsNameDiv ul li ,.productsNameDiv ul li a{ list-style-type: square; color: #fff; font-size: 16px;}
.productsNameDiv p  { text-align: center;}
.productsNameDiv .col-lg-6{position: relative; }
.productsNameDiv .col-lg-6:first-child { border-right: 2px solid #3f6397; }
a#basic-nav-dropdown { text-align: left; padding: 10px 10px!important;} 
 .nav-tabs  {
    margin-bottom: 3.4rem;
}
.nav-tabs button {
    font-size: 16px;
    font-weight: 600;
}
.float .fa , .float_left .fa { background-color: transparent; color:#fff;}
.float {
    background-color: #25d366;
    border-radius: 50px;
    bottom: 105px;
    box-shadow: 2px 2px 3px #999;
    color: #fff;
    font-size: 30px;
    height: 60px;
    line-height: 60px;
    position: fixed;
    right: 19px;
    text-align: center;
    width: 60px;
    z-index: 100;
}


.float_left  {
    background-color: #f501a1;
    border-radius: 50px;
    bottom: 25px;
    box-shadow: 2px 2px 3px #999;
    color: #fff;
    font-size: 30px;
    height: 60px;
    line-height: 60px;
    position: fixed;
    right: 19px;
    text-align: center;
    width: 60px;
    z-index: 100;
}
.my-float {
    margin-top: 16px;
}
.footerSection .insta .fa {   background-color: #fff; color:#008ae7 ; width: 45px; height: 45px;line-height: 45px; text-align: center; border-radius: 50%; -webkit-transition: .5s;  -o-transition: .5s;  transition: .5s;     font-size: 30px;
}
.footerSection .insta .fa:hover{background-color:#008ae7 ; color:#fff ;-webkit-transition: .5s;  -o-transition: .5s;  transition: .5s;}

.BabjiproductDiv:hover {     border: 2px solid #008ae7;   -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.BabjiproductDiv:hover.BabjiproductDiv p {
    color:  #008ae7;-webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;}
    
    .productDiv { min-height: 250px; background-size: cover; background-position: center; background-repeat: no-repeat;  position: relative; margin-bottom: 20px;     overflow: hidden; }
    .captionDiv {
        /* padding: 0px 24px; */
       text-align: center;
        height: 100px;
    }.captionDiv h3 {
        text-align: center;
        font-size: 25px;
        color: #00386b;
        font-weight: 600;
        text-transform: uppercase;
        height: 70px;
    }
    .captionDiv p { text-align: center;}
    .breadcrumb-title {
        background-color: #00386ba6;
        display: inline-block;
        padding: 17px 40px;     margin-top: 100px;

    }
 
 .captionDiv p a {
    border: 2px solid #00386b;
    padding: 4px 10px;
    display: block;
    margin:4px auto 20px;
    border-radius: 20px;
    width: 100px;-webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;    text-align: center;

}
.captionDiv p a:hover{ background-color: #00386b; color: #fff!important;-webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;     border: 2px solid #00386b;
}
.waterImages img{ padding:45px; }
 .productDiv .captionDiv {
     color: #0098a3;
    padding: 20px 10px;
    bottom: 0;
    position: absolute;
    width: 90%;
      top: 143px; 
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 21px;
    text-transform: capitalize;
    font-weight: 500;
    opacity: 1; padding: 15px 30px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.owl-carousel .productDiv .captionDiv {
     width: 100%;
    top: 201px;}

    .justify-content-center .productDiv .captionDiv {
        width: 90%;
       top: 201px;}
.productDiv .captionDiv .plus {     position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s;
    -webkit-transform: translateX(40px);
    -ms-transform: translateX(40px);
    transform: translateX(40px); }


  /*  .productDiv .captionDiv {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    padding: 15px 30px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    overflow: hidden;
    width: 90%;
}
*/

.productDiv .captionDiv h3 {
    font-size: 16px;
    font-weight: 700;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    color: #fff;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
    -webkit-transition-delay: .4s;
    -o-transition-delay: .4s;
    transition-delay: .4s;
}
.productDiv:hover .captionDiv h3 {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
}
.productDiv:hover .captionDiv:before {
     opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }

.productDiv .captionDiv:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color:rgb(25 73 13 / 70%);
    opacity: 1;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
   /* -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);*/
}
.borderTop {
    border-top: 1px dotted #919497;
    margin: 15px 0px;
}
.contactUS h5 { text-transform: uppercase;}
.contactUS p { font-weight: 500;       display: -webkit-box;
    font-size: 17px;
}
.contactUS p b {   color: #003075 !important;
    font-size: 14px;
    text-transform: uppercase;

}
.copyright { background-color: #00386b;
    color: #fff;
    border-top: 1px solid #738594;
}
.copyright p a { color: #fff!important;}
.copyright p { margin-bottom: 0px;}
.contactUS p a {
    color: #000000 !important;
    font-weight: 500;
    padding: 3px;
    font-size: 17px;
    font-weight: 600;
    line-height: 38px;
}
.contactUS h4 { font-weight: 600; color: #00386b;}
button.btn.btn-danger , button.btn.btn-danger:hover , button.btn.btn-danger:focus{
    background: #00386b; border: #00386b;
}
.productDiv .captionDiv .plus{    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
        opacity: 0;
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s;
    -webkit-transform: translateX(40px);
    -ms-transform: translateX(40px);
    transform: translateX(40px);
}

.productDiv:hover .captionDiv {
    opacity: 1;
}
.productDiv:hover .plus {    
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px); opacity: 1; }

.productDiv .captionDiv .plus a {
    background-image: -webkit-linear-gradient( 0deg,  rgb(51 129 32 / 70%)  0%, rgb(25 73 13 / 50%)100%);
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    text-decoration: none;
}
 /* ===================== */
 .owl-theme .owl-nav .owl-left , .owl-theme .owl-nav .owl-next{ left:0; position: relative; transition: all 0.3s ease-in-out;}
 .owl-theme .owl-nav [class*='owl-']:hover {
    background: transparent;
    color: #FFF;
    text-decoration: none;
}
 .owl-theme .owl-nav .owl-left:hover{  right: 10px; position: relative; transition: all 0.3s ease-in-out;}
.owl-theme .owl-nav .owl-next:hover {  left: 10px;  position: relative;transition: all 0.3s ease-in-out;
}

@media (min-width:991px) and (max-width:1400px){
    #background-video {  margin-top: -100px!important;    }
    .breadcrumb-area{     margin-top: -97px!important;   }
}
@media (min-width: 767px) and (max-width:991px){
    #background-video{     margin-top: -65px;
    }
    .breadcrumb-area {     margin-top: -96px!important;
    }
    .navbar-expand-lg {
        border: none;
        background-color: rgb(0 56 107);
        z-index: 2;
    }
    .connectSection .conncetDiv {
        padding: 40px 40px;
        margin-top: 0%;
    }
    .navbar-nav a{color: #fff; padding: 5px 10px;}
    .dropdown-item  a{color: #fff;}
    .shipSection .col-lg-3 {
        margin: auto;
        display: block;
        text-align: center;
    }
    .navbar-brand .img-fluid {
        max-width: 22%;
        height: auto;
    }
    button.pull-right.navbar-toggler {
        margin-top: 25px;
    }
    .owl-theme .owl-nav {
        margin-top: 10px;
        position: absolute;
        top: -51%!important;
        right: 0px;
    }
    .BannerDiv {  min-height: 50vh!important;}

    .bannerText h2 {  color: #fff; font-size: 48px;}

    .aboutText { text-align: center; color: #fff;}

    .shipSection .col-lg-3 h4 p {
        text-align: center;
        margin-bottom: 20px;
    }
    
}
@media (max-width: 480px){
    .topBar span {
        font-size: 13px!important;
    }
}
@media (max-width: 767px){
    #background-video{     margin-top: -73px;
    }
    .companyProgress img , .HomeForm img{ margin-top: 20px;}
    .addressDiv h5 { text-align: left!important;}
    button.pull-right.navbar-toggler {
        margin-top: 8px;    border-color: #959fbe;

    }
    .connectSection .conncetDiv {
        padding: 30px 30px;
        margin-top: 3%;
    }
    .navbar-expand-lg {
        border: none;
        background-color: rgb(0 56 107);
        z-index: 2;
    }
    .navbar-nav a{color: #fff;}
    .dropdown-item  a{color: #00386b;}

    .connectSection .col-lg-6 {
        min-height: 506px}
        .testiText{padding: 5px 4px 5px 0px;}
    .col-lg-4 .navbar-nav{ background-color: transparent!important;}
    .process-items .item { margin-bottom: 20px;}
    .bannerText h2 {  color: #fff; font-size: 48px;}
    .BannerDiv {  min-height: 50vh!important;     background-position: inherit;
    }
    .owl-theme .owl-nav {
        margin-top: 10px;
        position: absolute;
        top: -60px!important;
        right: 0px;
    }
    .AboutBg p {
        margin-bottom: 55px;
    }
    .navbar-nav span{ font-size: 14px;}
    .carousel-caption h3{     font-size: 16px;  }
    .carousel-caption {     bottom:0.25rem;  }
    .btn { padding: 5px!important; font-size: 13px;    margin-bottom: 10px;}
    .AboutSec p {
        font-size: 16px;
        padding-right: 0px;
    }
    .container h1 { text-align: center; font-size: 30px;}
    .testimonials  .px-5 {
        text-align: center;
        margin-top: 30px;
    }
    .testimonials h4 { line-height: 34px;}
    .testimonials p { text-align: center;}
    .reasonsSec .text h4 {
        font-weight: 700;
        color: #000;
        font-size: 17px;
    }
    .reasonsSec img {
        float: left;
        height: 60px;
        width: 60px;
    }
    .testimonials h2 { font-size: 26px;     line-height: 34px;    }
    .navbar-brand .img-fluid {
        max-width: 25%;
        height: auto;
        margin-top: -8px
    }
    .container h5 {
        text-align: center;
    }
    .navbar>.container {
        display: flow-root!important;
        margin: 12px 0px;
    }
    .navbar .col-lg-4 a, .navbar .col-lg-3 a {
        line-height: 25px;
        margin-top: 10px;
    }
    .navbar-nav .active {
        color: #00386b !important;
        
    }
    .breadcrumb-title h2 {
        font-size:20px!important;
        margin: 25px 0px;}
    span.navbar-brand {
        width: 270px;
    }
    .aboutSection p{ font-size: 16px;
        line-height: 25px;
    }
    .advantageSection {
        background-color: #f1f1f1;
        padding: 20px 0px;
    }
    .topBar span {
        font-size: 14px;
    }
    .shipSection .col-lg-3 {margin: auto;
        display: block;
        text-align: center; }
        .shipSection .col-lg-3 h4 { text-align: center; margin-top: 15px;     font-size: 35px;
  font-weight: 600;  color:#003075;       }
        .shipSection .col-lg-3 h4 p { text-align: center; margin-bottom: 20px;; }
    .breadcrumb-area {     min-height: 142px!important ;    margin-top: -72px!important;
    }
    .ProductsSection  img { margin: 20px auto; display: block;}
    .navbar-nav a  ,.navbar-nav .active{ padding: 6px 10px!important;  }
    .aboutSection h2 { color: #fff; font-size: 25px; text-align: center;}
    .aboutSection { background-color: #003075; padding: 20px 0px;}
    .py-5 {
        padding-top: 15px!important;
        padding-bottom: 15px!important;
    }
    .navbar .col-lg-3 a, .navbar .col-lg-6 a {
        line-height: 32px; overflow-x: scroll;
    }

    .advantageSection .container h1 {
        font-size: 30px;
        text-align: center!important;
    }
}

@media (min-width: 992px){
.navbar-expand-lg .navbar-collapse {
    display: grid!important;
    flex-basis: auto;
}
span.navbar-brand {
    margin-right: -151px;
}
 
.dropdown-menu a { margin-left: 0; border-top: none!important;  width: auto!important;  padding: 5px 4px!important;}
.aboutSection { position: relative;  background-size: cover;  background-repeat: no-repeat;  background-position: 270px 0px;
}.aboutSection img { z-index: 10;  position: relative;     
}

/* .aboutSection::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-color: transparent;
    border-style: solid;
}

.aboutSection::before {
    border-width: 212px;
    border-right-color: #003075;
    border-top-color: #003075;
    border-left-color: #fff;
    border-bottom-color: #fff;

} */


.col-lg-6 .navbar-nav a {
    display: block;
    color: #fff;
    font-weight: 500;
    /* padding: 20px 10px; */
    position: relative;
    font-size: 15px;
    width: auto;
    text-align: center;
    font-weight: 600;
    line-height: 57px;
    text-transform: uppercase;
    margin-right: 20px;    position: relative;


 
}
.col-lg-6 .navbar-nav li:after{position: absolute;
    content: "";
    height: 100%;
    width: 1px;
    background-color: #f1f1f1;
    right: -15px;
    top: 0;}
.col-lg-6 .dropdown-menu   a { text-align: left;     margin-right: 0px;     background: #00386b;

}
.homeMap .fa { color: #00386b; margin-right: 10px; }
.homeMap h4 { text-transform: uppercase; color: #00386b;}
.homeMap p { margin-top: 20px; color: #000; font-weight: 600; font-size: 16px;;}
.homeMap p a{   color: #000; font-weight: 600;}
.col-lg-6  .nav-item.dropdown {
    padding: 10px 10px;
}
.col-lg-6 .dropdown-menu .nav-item.dropdown {
    padding: 2px 2px;
}
.col-lg-6 .navbar-nav a.active{ text-align: left;}
aside h3 {
    font: bold 12px Sans-Serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: #369;
    color: #fff;
    padding: 5px 10px;
    margin: 0 0 10px 0;
    line-height: 24px;
  }
  
  /* Class name via Modernizr */
   aside {
    border-left: 34px solid #369;
    padding-left: 10px;
    position: relative;
  }
   aside h3 {
    /* Abs positioning makes it not take up vert space */
    position: absolute;
    top: 0;
    left: 0;
  
    /* Border is the new background */
    background: none;
  
    /* Rotate from top left corner (not default) */
    transform-origin: 0 0;
    transform: rotate(90deg);
  }

    .art-side-form-side-text {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    margin: 0;
    width: 0;
    white-space: nowrap;
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    text-align: left;
    position: absolute;
    padding-bottom: 1rem;
    font-size: 25px;
     font-weight: 600;
    letter-spacing: 0.1em;
    color: #fff;
}

.art-side-form-side-text:after {
    border-bottom: 1px solid #3f6397;
    color: #fff;
    position: absolute;
    content: "";
    height: 0px;
    width: 12rem;
    left: 3px;
    bottom: 0px;
    top: 25px;
    margin-left: 125px;
}

.art-side-form-side-text2 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    margin: 0;
    width: 0;
    white-space: nowrap;
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    text-align: left;
    position: absolute;
    padding-bottom: 1rem;
    font-size: 25px;
     font-weight: 600;
    letter-spacing: 0.1em;
    color: #003075;left: 50%;
    top: 50%;
}
.productSection .col-lg-1 { position: relative;}

    .ProductsSection .col-lg-2{ text-align: left;}
    .ProductsSection .col-lg-2 li{ text-align: left;}
    .secondHeading { color: #003075;     display: table;

        border-bottom: 1px solid #0098a3;
        text-align: center;
         margin: auto;
        text-align: center;     font-size: 22px;
    }

.art-side-form-side-text2:after {
    border-bottom: 1px solid #3f6397;
    color: #003075;
    position: absolute;
    content: "";
    height: 0px;
    width: 12rem;
     bottom: 0px;
    top: 35px;
    margin-right: 125px;
}
.shipSection .col-lg-3 { display: flex; align-items: center;}
.shipSection img { max-width: 40%!important; margin-right: 15px;  }
.shipSection h4 {color:#003075; font-size: 30px; font-weight: 700; margin-bottom: 10px;}
.shipSection h4 p { font-size: 16px; color: #009ee7;     margin-bottom: 0px;
    
    font-weight: 500;
}
 

}


/* =====================================Subheadr ===================================*/
section.breadcrumb-area :hover .breadcrumb-area { transform: scale(1.1);  -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;  }
    .breadcrumb-area {     background-size:cover;     position: relative;     background-repeat: no-repeat;
        margin-top: 0px;    display: flex;    align-items: center;    transform: scale(1);  background-position: center;
        min-height: 400px;
    margin-top: -100px;    }

  /* .breadcrumb-area:before {
  background-color: hwb(245deg 2% 71% / 70%); position: absolute; content: ''; left: 0; top: 0; width: 100%;height: 100%; } */
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: #00386b;
    color: #fff!important;
}
.dropdown-item:active .navbar-nav a , .dropdown-item:hover .navbar-nav a {     color: #fff!important;
    background-color: #00386b;
}
  .breadcrumb {
  display: inline-block;
  -ms-flex-wrap: wrap;
  flex-wrap: unset;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: unset;
  border-radius: 0;
  
  }
  .breadcrumb li {display: inline-block;   font-size: 11px;
  }
  .breadcrumb li a {
  font-size: 11px;
  color: #fff;
  font-weight: 500;
  }
  .breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 15px;
  padding-left: 10px;
  color: #ddd;
  content: "|";
  }
  .breadcrumb-title h2 {
  font-size: 48px;
  margin-bottom: 25px;
  line-height: 1;
  color: #fff;    font-weight: 800;

  }
  .breadcrumb-title p {
  margin-bottom: 0;
  color: #777;
  font-size: 16px; 
  }
  .breadcrumb > .active {
    color: #ffffff !important;
    font-weight: 500;
  }
  .objectives li {
   position: relative;
    color: #000;
    font-size: 16px;
    padding: 14px 0px;
    padding-left: 15px;
  }
  
  .breadcrumb-wrap:first-child {
    display: block;
      padding: 20px;
      color: #fff;
      position: relative;
      text-align: center;
  }
  .objectives li:before { 
   position: absolute;
    content: "";
    width: 0;
    left: -12px;
    height: 0;
    top: 18px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #4fae57; }
  
    /*--------------------------------*/


    @media (min-width: 768px) {
        .animate {
          animation-duration: 0.3s;
          -webkit-animation-duration: 0.3s;
          animation-fill-mode: both;
          -webkit-animation-fill-mode: both;
        }
        .dropdown-menu a {
            line-height: initial!important;
        }
        .navbar-nav .active {
            color: #ffcb15 !important;
            border-radius: 0px;    border-bottom: 3px solid #ffcb15;


 
        }
        .dropdown-menu .active {
            margin-top: 4px!important;
             border: none;
            height: auto;
            color: #ffcb15 !important; line-height: initial!important;
        }
        /* .nav-item.dropdown:hover .dropdown-menu {
            display: block;     margin-top: -1px;

        }

        .nav-item.dropdown.dropdown-menu:hover .dropdown .dropdown-menu{
            display: block;     margin-top: -1px;

        } */
        .dropdown-menu.show.dropdown-menu-end {
            margin-top: -1px;     background: #00386b;

        }
        .carousel-caption {
            position: absolute;
            /* right: 0%; */
            bottom: 1.25rem;
            left: 9%;
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
            color: #fff;
            top: 40%;
            text-align: center;
        }
        .carousel-caption h3 {
            font-weight: 700;
            font-size: 38px;
            text-align: left;
            line-height: 45px;
            display: table;
            background: rgba(255,255,255,0.8);
            color: #000;
            padding: 10px;
            letter-spacing: 1px;
            text-transform: uppercase; margin-bottom:20px;
        }

        .banner1{background:none!important;color: #fff!important;}

            .carousel-caption .btn { margin-right: 20px;    float: left;width: 150px;
            }
      }
      
      @keyframes slideIn {
        0% {
          transform: translateY(1rem);
          opacity: 0;
        }
      
        100% {
          transform: translateY(0rem);
          opacity: 1;
        }
      
        0% {
          transform: translateY(1rem);
          opacity: 0;
        }
      }
      
      @-webkit-keyframes slideIn {
        0% {
          -webkit-transform: transform;
          -webkit-opacity: 0;
        }
      
        100% {
          -webkit-transform: translateY(0);
          -webkit-opacity: 1;
        }
      
        0% {
          -webkit-transform: translateY(1rem);
          -webkit-opacity: 0;
        }
      }
      
      .slideIn {
        -webkit-animation-name: slideIn;
        animation-name: slideIn;
      }
      .contactUS img { margin: auto; max-width: 10%;}
      .contactUS p {
        margin-bottom: 5px;
    }
     
      /* Other styles for the page not related to the animated dropdown */
      
      /*Animation*/

      @keyframes rocking {
  0% {
      transform: scale(1);
  }
  25% {
      transform: scale(1.1);
  }
  50% {
      transform: scale(1);
  }
  75% {
      transform: scale(1.1);
  }
  100% {
      transform: scale(1);
  }
}
@-webkit-keyframes Gradient {
  0% {
      background-position: 0 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0 50%;
  }
}
@-moz-keyframes Gradient {
  0% {
      background-position: 0 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0 50%;
  }
}
@keyframes Gradient {
  0% {
      background-position: 0 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0 50%;
  }
}
 .effetMoveGradient, .effetMoveGradient:hover {
    /* text-shadow: 0 3px 6px #00003e;
  background-color:  #629ea1;
  background: linear-gradient(-45deg,  #00003e, #ee402f, #00003e,#629ea1); */

  background-size: 400% 400%; 
     -webkit-animation: Gradient 3s ease infinite,rocking 3s ease infinite;
    animation-delay: 2s;
    -moz-animation: Gradient 3s ease infinite,rocking 3s ease infinite;
    animation-delay: 2s;
    animation: Gradient 3s ease infinite,rocking 3s ease infinite;
    animation-delay: 2s;
  } 


  /* ======================================================= */
  .container2 {
    max-width:900px;
    margin:50px auto;
  }
  .timeline{
    position:relative;
    display:flex;
    flex-direction:column;
    list-style:none;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  /* the timeline's center vertical line */
  .timeline:before{
    content:"";
    position:absolute;
    top:0;
    bottom:0;
    left:50%;
    width: 1px;
    background-color:  #56c587;
  }
  .timeline li{
    border-top:1px solid  #56c587;
    position:relative;
    width:50%;
    min-height:100px;
    margin-bottom:20px;
    padding: 20px 40px;
    text-align: right;
    transition:.3s;
    opacity:0;
    transform: translateX(-4%);
  }
  /* the rounded mark on each timeline item */
  .timeline li:before {
    content:"";
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0;
    right: 0;
    background: #56c587;
    border-radius:50%;
    box-sizing:border-box;
    transform: translate(50%,-50%);
  }
  .visible img {
    position: absolute;
    top: -50px;     max-height: 75px;
}


    .timeline li:nth-child(2n) img{  position: absolute;
        top: -50px;     max-height:75px; left: -74px; }
  .timeline h3 {
    font-size:28px;
    margin-bottom: 10px;margin-top: 25px;
  }
  .timeline p {
    font-size: 20px;
    line-height: 28px;    text-align: revert;

  }
  .timeline li:nth-child(2n) {
    align-self: flex-end;
    text-align: left;
    transform: translateX(-4%);
  }
  .timeline li:nth-child(2n):before {
    left: 0;
    transform: translate(-50%,-50%);
  }
  .timeline li.visible {
    opacity:1;
    transform:translateX(4);
  }
#map_canva {
  
  display: table;
  
  width: 100%;
  
  height: 500px;
  
  }

  


  .owl-theme .owl-nav {
    margin-top: 10px;
    position: absolute;
    top: -45%;
    right: 0px;
}



/* ========================================= */


.image-holder{
    height: 600px;
    width: 100%;
    background:  no-repeat;
    background-size: cover;
    transition: transform 3s ease;
    transform: scale(1);
  }
  
  .image-holder.scale-img{
    transform: scale(2);
  }
  
  .image-container{
    height: 600px;
    overflow: hidden;
    position: relative;
  }
  
  
  .labelText{
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
  
  .content{
   padding: 24px;
  }